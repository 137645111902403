@import '../styles/base';

.header{
    box-shadow: 4px 4px 4px lightblue;
    background: white;
    width: 100%;
    position: fixed !important;
    z-index: 10;
    top:0;
    font-size: $smallfont;
}
.logo-img{
    box-shadow: 4px 4px 4px white;
}

.logo{
    min-width:350px ;
}

a:link, a:visited { color:black }
a:hover { color:purple !important;
    text-decoration: none !important;
    transform: scale(1.05) !important;
 }

a:active, a:focus{
    color: lightblue !important;
}

.navigation span{
    margin: 0 10px;
}

@media screen and (max-width: 950px) {
    .logo {
      border-radius: 10px;
    }
    .header{
        flex-direction: column;
    }
  }