.slider{
    border-radius: 5px;
    width:45vw;
    min-width: 500px;
    padding: 10px;
}
.slider h2{
    color: white;
}
@media screen and (max-width: 500px) {
    .slider{
        min-width:400px;
        padding: 10px 20px;
    }
  }