@import '../styles/base';
.about{
    margin: 100px auto;
    font-size: $smallfont;
}

.about-text{
    width:45vw;
    min-width: 500px;
    padding: 10px;
    background: white;
}


@media screen and (max-width: 500px) {
    .about-text{
        min-width:450px;
        padding: 10px 20px;
    }
  }
