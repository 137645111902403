@import '../styles/base.scss';

.career-data{
    background-image: linear-gradient(to right,rgba(9, 10, 14, 0.5),rgba(3, 4, 8, 0.5)),
    url(https://images.pexels.com/photos/1311518/pexels-photo-1311518.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260);
    height: 300px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
}

.headline{
    color:white;
    font-size: 30px;
    background: rgba(128, 0, 128, 0.603);
    font-style: italic;
    width: 200px;
    padding: 10px;
    margin: 20px auto;
}

.careers{
    margin: 100px 0 0 0;
    font-size: $smallfont;
}