@import './base';

.flex{
    display: flex;

    &.column{
        flex-direction: column;
    }

    &.space-between{
        justify-content: space-between;
    }
    &.flex-start{
        align-items: flex-start;
    }
    &.space-evenly{
        justify-content: space-evenly;
    }

    &.center-align{
        align-items: center;
    }
    &.wrap{
        flex-wrap: wrap;
    }
}

.decoration-none{
    text-decoration: none;
}

.btn1{
    border: none;
    border-radius: 3px;
    padding: 5px;
    background-color: $mainblue ;
    cursor: pointer;
    color: white;
}

/* FONT SIZES */

.small-font{
    font-size: $smallfont;
}

.medium-font{
    font-size: $mediumfont;
}

.large-font{
    font-size: $largefont;
}

/* FONT COLORS  */
.white-font{
    color: $mainwhite;
}
.purple-font{
    color:$mainpurple;
}

/* BACKGROUND COLORS */
.mainblue{
    background-color: $mainblue;
}
.mainpurple{
    background-color: $mainpurple;
}

/* TEXT ALIGNMENT  */

.left-text{
    text-align: left;
}
.center-text{
    text-align: center;
}
.right-text{
    text-align: right;
}
.justify-text{
    text-align: justify;
}

.sub-heading{
    font-size: $largefont;
    color: $mainblue;
    border-bottom: 2px solid $mainpurple;
    text-align: center;
    font-weight: bold;
    margin-bottom: 100px;
}
.sub-title{
    font-size: $mediumfont;
    color: $mainpurple;
    text-align: center;
    margin: 20px 0;
    padding: 10px;
}
.title{
    font-size: $smallfont;
    color: black;
    text-align: center;
    margin: 20px 0;
}

.greeting{
    font-size: 30px;
    color: $mainblue;
    font-style: italic;
    text-shadow: 2px 2px #a200ff69;
    margin-bottom: 20px;
}
/* PADDINGS & MARGINS */

.section-margin{
    margin: 100px 0;
}
.content-margin{
  margin: 10px 0;
}