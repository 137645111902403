@import '../styles/base';
.service{
    width: 400px;
    padding: 0 0 10px 0;
    background: #F7F7F7;
    box-shadow: 2px 2px 2px 2px lightgray;
    transition: 1s;
    font-size: $smallfont;

}

.service:hover{
    transform: scale(1.01) translateY(-5px);
}
.action-buttons{
    margin: 20px;
}
.service-description{
    padding: 10px;
}