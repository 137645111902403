
.small-width{
    width: 25vw;
}

.full-width{
    width: 100%;
}

.half-width{
    width: 50vw;
}

.bigger-width{
    width: 75vw;
}

.small-height{
    height:80px;
}

.mid-margin-bottom{
 margin-bottom: 100px;
}