@import '../styles/base';

.welcome{
  padding: 10px;
  height: 70vh;
  color: white;
  margin-top: 70px;
  font-size: $smallfont;
  background-image:  linear-gradient(
    rgba(8, 16, 20, 0.466), 
    rgba(5, 7, 8, 0.479)),url(https://images.pexels.com/photos/2391/dirty-industry-stack-factory.jpg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940);
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.welcome-text{
  width: 60vw;
}
.welcome-text-main{
  font-size: $xtra-large;
  text-shadow: 2px 2px $mainpurple;
  animation: slideRight ease-in-out 3s;
  transition: 1s;
  &:hover{
    transform: translateX(10px);
  }
}


.welcome-text-main1{
  font-size: $xtra-large;
  text-shadow: 2px 2px $mainpurple;
  animation: slideLeft ease-in-out 3s;
  transition: 1s;
  &:hover{
    transform: translateX(-10px);
  }
}

.welcome-text-sub{
  font-size: $largefont;
  color: $mainpurple;
  text-shadow: 1px 1px $mainwhite;
  transition: 1s;
  &:hover{
    transform: translateY(10px);
    text-shadow: 2px 2px $mainpurple;
  }
}
.quote{
  border-radius: 50%;
  background-color:$mainblue ;
  padding: 40px;
  animation-name: slideRight;
  animation-duration: 5s;
  animation-timing-function: ease-in-out;
    color: white;
    margin-bottom: 20px;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.281);
}

.quote-call{
background: $mainpurple;
padding: 10px;
margin: 10px 0;
border-radius: 5px;
animation: slideRight 3s ease 3s;
}

.quote-email{
  background: $mainblue;
  padding: 10px;
  border-radius: 5px;
  animation: slideRight 3s ease 3s;
}

/* ANIMATIONS */
@keyframes slideRight {
  0% {
    transform: translateX(50px);
    opacity: 0;

  }
  80% {
    transform: translateX(-20px);
    opacity: 1;
      }
  100% {
    transform: translateX(0);
    opacity: 1;
      }
}

@keyframes slideLeft {
  0% {
    transform: translateX(-150px);
    opacity: 0;

  }
  80% {
    transform: translateX(20px);
    opacity: 1;
      }
  100% {
    transform: translateX(0);
    opacity: 1;
      }
}
